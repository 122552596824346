#access-recruteur ul.nav {
    margin-top: 15px;
}

#create-profile_steps {
  margin-bottom: 25px;
  border-top: 1px solid #cacaca;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
}

.home-slogan {
  background: transparent !important;
  margin-top: -100px !important;
}

.home-slogan h3 {
  color: #04268C;
  font-size: 1.9em;
  margin: 0;
  margin-bottom: 5px;
  letter-spacing: 1px;
}

.home-slogan span {
  color: #EC6432;
  font-size: 1.2em;
  font-weight: bold;
  letter-spacing: 1px;
}

.bootstrap-select button[role='button'] {
  padding: 8px 25px;
  border: none;
  background: #FFF;
  color: #000;
  transition: .5s;
  border: 1px solid #ccc;
}

.dropzone {
  padding: 20px 10px;
}

.dropzone-files {
  height: 20px;
  min-height: 20px;
  background: #FFF !important;
  font-size: 1em;
  border: 0px;
  text-decoration: underline;
    color: #359d59;
}

.dropzone-files .fa {
    color: #359d59;
    text-decoration: underline;
    font-size: 1.4em;
}


.dropzone .dz-message {
    text-align: center;
    margin: 0;
}

.btn-primary .fa, .btn-danger .fa, .btn-success .fa {
  color: #FFF;
}

ul.dropdown-menu li {
  margin: 0;
}

ul.list-group li {
  margin: 0;
}

.list-group-item {
  margin-bottom: -1px !important;
}

h3.panel-title {
  color: #FFF;
  font-size: 16px !important;
}

.panel-title .fa {
  color: #FFF;
}

#create-profile_edit ul {
  margin-bottom: 0px !important;
}

#create-profile_steps .final-button {
  width: 100%;
  border-radius: 5px;
  background: #359d59;
  text-transform: uppercase;
  color: #FFF;
  padding: 8px 25px;
}

.orange {
  background: #fe7200;
  color: #fff;
  border-color: #fe7200;
}

.btn {
  border-radius: 0px;
}

.active-red {
  padding:5px 10px;
  background:red;
  color: #fff !important;
}

.active-warning {
  padding:5px 10px;
  background: #fe7200;
  color: #fff !important;
}

.banner {
  background: white;
  height: 50px;
}

.banner .bgd-blue{
  background: #2b7cc7;
  border-radius: 5px;
  margin: 0;
}


.banner a.big {
  color: #FFF;
  line-height: 50px;
  font-size: 1.5em;
}

.banner  .fa, .banner .glyphicon {
  color: #FFF;
}

.banner .tooltip{
  min-width: 130px;
}

.banner .navbar-nav > li > a {
  padding-top: 14px;
  padding-bottom: 14px;
}

.banner .nav .open > a, .banner .nav .open > a:hover, .banner .nav .open > a:focus {
  background-color: transparent;
  border-color: #fff;
}

.banner .nav > li > a:hover, .banner .nav > li > a:focus {
    text-decoration: none;
    background-color: #2b7cc7;
}

.banner .nav > li > a:hover .glyphicon, .banner .nav > li > a:focus .glyphicon{
    color: #cdcdcd;
}

#classified {
  margin-top: 20px;
}

#classified ul.article-infos li {
  display: inline-block;
  margin: 0;
  color: #9c9c9c;
}

#classified ul.article-infos li::after {
    content: ' | ';
}

#classified ul.article-infos li:last-child::after{
  content:none;
}

#classified .col-sm-4 .panel-body h5 {
  padding: 5px 0;
}

#classified .col-sm-4 .panel-body .glyphicon {
  float: left;
  margin-right: 10px;
  color: #959595;
  font-size: 1.5em;
}

#classified .article_description h4 {
  margin-top: 30px;
  color: #2b7cc7;
}

#classified .col-sm-4 .panel-body p {
  padding-top: 5px;
  padding-left: 32px;
  color: #959595;
  font-weight: 400;
}

#access-candidat .col-md-9 input[type='text'] {
  width: 100%;
  height: 34px;
}

#access-candidat .col-md-9 .panel:first-child {
  margin-top: 10px;
}

#access-candidat .col-md-9 textarea {
  height: 100px;
}

#access-recruteur ul.nav li {
  display: inline-block;
  margin: 0px;
  border: 1px solid #cacaca;
  border-bottom: 0;
}

#new-account .col-sm-6.part2 a,
#new-account .col-sm-6.part2 span {
  font-size: 1em;
}

.dropzone {
  border: 0px;
  background: #EEE;
}

li.active .fa {
  color: #FFF;
}

.option-icon {
  margin-right: 5px;
}

#principal_output .article-part1 .article-infos > span:last-child::after {
  padding: 0 3px;
  content: '';
}

#access-recruteur .container-fluid:last-child {
  border-top: 1px solid #359d59;
}

footer .container-fluid {
  margin-bottom: 75px;
  border: 0px;
  border-top: 1px solid #cacaca;
  border-bottom: 1px solid #cacaca;
  background: white;
  color: #989898;
}

.step-avatar .dropzone  {
  min-height: 20px;
  background: #FFF;
  padding: 0 15px;
  text-align: left;
}

.step-avatar .dz-message  {
  text-align: left;
}

.notification .fa-envelope {
  padding: 0 25px;
  float: right;
  color: #737373;
  font-size: 4em;
}

#create-profile_edit .page .well .col-md-6 {
  padding: 30px;
  padding-top: 0px;
  min-height: 650px;
}

#create-profile_edit ul.filter-list li {
  position: relative;
  right: 15px;
  margin: 0;
  padding: 10px 15px 10px;
  width: 105%;
  border-bottom: 1px solid #cacaca;
}

.btn-underline {
  text-decoration: underline;
  color: #359d59;
  background: #FFF;
  border: 0;
}

.btn-underline:hover {
  background: #EEE;
  color: #999;
}

#access-candidat .col-md-3 .profile-pic img {
    max-width: 250px;
    width: 100%;
    border-radius: 5px;
}

.profile-pic {
  padding-bottom: 0px;
}

.profile-pic a {
  position: relative;
  bottom: 30px;
  padding: 2px 10px 2px 5px;
  background: black;
  color: white;
}

.profile h3 {
  font-size: 16px;
  margin-top: 0px;
}

ul.header-menu {
margin-top: 15px;
padding-left: 0;
margin-bottom: 0;
list-style: none;
}

ul.header-menu li {
  display: inline-block;
  margin: 0px;
  border: 1px solid #cacaca;
  border: 0;
}

ul.header-menu li a {
  position: relative;
  display: block;
  padding: 5px 25px;
  color: #2b7cc7;
}

ul.header-menu li a:hover {
  background: #2b7cc7;
  color: white;
}

ul.header-menu li.active a {
  background: #2b7cc7;
  color: white;
}

#access-candidat .col-md-9 button[role='button']:hover {
  background: #FFF;
}

/*#access-candidat .col-md-9 button[role='button'] {
  padding: 8px 25px;
  border: none;
  background: #FFF;
  color: #000;
  transition: .5s;
  border: 1px solid #ccc;
}*/

.active-profile {
  background: #449d44 !important;
  border-color: #449d44 !important;
  color: #FFF !important;
}

.active-profile .fa {
  color: #FFF !important;
}

.btn-round {
  float: right;
  background: none;
  color: #96a1b1;
  z-index: 100;
  width: 35px;
  height: 35px;
  border: 1px solid #96a1b1;
  padding: 0;
  line-height: 35px;
  border-radius: 50%;
}

.bold-label {
  font-weight:bold;
}

.media.files .media-body a {
  display:block;
}
@media (min-width:992px) {
  .media.files .media-body a {
    max-width:90px;
  }
}

.fa-2x {
  font-size:2em;
}
.fa-3x {
  font-size:3em;
}
.fa-4x {
  font-size:4em;
}
.fa-5x {
  font-size:5em;
}

#access-candidat .panel-heading {
  color: #2b7cc7;
}

.blue {
  color: #2b7cc7 !important;
}

span.last-minute {
  margin-left: 10px;
  padding: 2px 10px;
  background: #666;
  color: white;
  font-size: 0.6em;
  white-space: nowrap;
}

.company-name{
  margin-top: -30px !important;
}

.blue-icon{
  color: #2b7cc7;
  vertical-align: bottom;
}

.align-icon{
  margin-right: 4px;
}

.filter-km{
  border-bottom: 0 !important;
  margin-top: 15px !important;
}

.filter-km h4{
  font-size: .85em !important;
}

.filter-km .glyphicon{
  margin-right: 5px !important;
  font-size: 15px !important;
}

.coma-list:last-of-type{
  display: none;
}

.show-classifieds .prinicpal_search_infos{
  display: none !important;
}

#principal_output_mobile .principal_output .article-part1 > span {
  width: 180px !important;
}